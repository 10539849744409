<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="modify-password main-section">
      <el-form
        :model="formModel"
        :rules="rules"
        :inline-message="true"
        label-width="1.5rem"
        ref="formModel"
      >
        <el-form-item label="新密码" prop="password">
          <el-input
            v-model.trim="formModel.password"
            type="password"
            placeholder="请输入新登录密码"
            autocomplete="new-password"
            maxlength="18"
          />
          <div class="hint">
            请输入6到18位密码，包含数字、特殊字符、大写和小写字符(任意两种)。
          </div>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input
            v-model.trim="formModel.confirmPassword"
            type="password"
            placeholder="请再次输入新登录密码"
            autocomplete="new-password"
            maxlength="18"
          />
          <div class="hint">
            请输入6到18位密码，包含数字、特殊字符、大写和小写字符(任意两种)。
          </div>
        </el-form-item>
        <el-form-item label="手机号">
          <span class="phone">{{ userInfo.phone }}</span>
          <span class="ls-btns-block spin code" @click="sendEmailCode()">
            <Loading :loading="sendMessLoading">
              <span>{{ isCount ? timeCount + "s" : "发送验证码" }}</span>
            </Loading>
          </span>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
          <el-input
            v-model="formModel.smsCode"
            placeholder="请输入验证码"
            maxlength="6"
          />
        </el-form-item>
        <div class="ls-btns-block btn-submit" @click="submitFunc('formModel')">
          确认修改
        </div>
      </el-form>
    </div>
  </section>
</template>
<script>
import BreadCrump from "@/components/common/BreadCrump.vue";
import Loading from "@/components/common/Loading.vue";
import { sendMessUpdatePass, updatePassword } from "@/api/interface/login.js";
import { mapGetters } from "vuex";
import { SHA256 } from "@/utils/sha256";

export default {
  components: {
    BreadCrump,
    Loading,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg =
          /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,18}$/;
        if (!reg.test(value)) {
          callback(
            new Error(
              "请输入6到18位密码，包含数字、特殊字符、大写和小写字符(任意两种)"
            )
          );
        }
        callback();
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formModel.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      breadcrumbList: [{ menuName: "修改密码" }],
      formModel: {
        password: undefined,
        confirmPassword: undefined,
        smsCode: undefined,
      },
      rules: {
        password: {
          required: true,
          validator: validatePass,
          trigger: ["change", "blur"],
        },
        confirmPassword: {
          required: true,
          validator: validateCheckPass,
          trigger: ["change", "blur"],
        },
        smsCode: { required: true, message: "请输入验证码" },
      },
      sendMessLoading: false,
      timeCount: 60,
      isCount: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    sendEmailCode() {
      if (this.isCount) return;
      this.sendMessCode();
    },
    sendMessCode() {
      this.sendMessLoading = true;
      sendMessUpdatePass({})
        .then((res) => {
          this.$message.success("发送成功！");
          this.noteInfoDisabled();
        })
        .catch()
        .finally(() => {
          this.sendMessLoading = false;
        });
    },
    noteInfoDisabled() {
      this.isCount = true;
      this.timeCount -= 1;
      let timer = setInterval(() => {
        if (this.timeCount <= 0) {
          this.isCount = false;
          this.timeCount = 60;
          clearInterval(timer);
          timer = null;
        } else {
          this.timeCount -= 1;
        }
      }, 1000);
    },
    submitFunc(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassword({
            confirmPassword: SHA256(this.formModel.confirmPassword),
            password: SHA256(this.formModel.password),
            smsCode: this.formModel.smsCode,
          }).then((res) => {
            localStorage.clear();
            sessionStorage.clear();
            this.$message.success("密码修改成功，请重新登录！");
            this.$router.push({
              name: "Login",
            });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modify-password {
  margin-top: 16px;
  background-color: #fff;
  min-height: 703px;
  padding: 69px 400px 0;

  .hint {
    font-size: 12px;
    line-height: 15px;
    color: #8a8a8a;
    margin: 7px 0 0 9px;
  }

  .phone {
    font-size: 14px;
    color: #8a8a8a;
    line-height: 49px;
  }

  .phone,
  .code {
    display: inline-block;
    vertical-align: middle;
  }

  .code {
    margin-left: 46px;
    height: 39px;
    line-height: 39px;
  }
  .btn-submit {
    margin-top: 6px;
    margin-left: 180px;
  }
}

:deep() .el-form {
  .el-form-item {
    margin-bottom: 57px;
  }

  .el-form-item__label {
    font-size: 14px;
    color: #3d3d3d;
    line-height: 49px;
    padding: 0 36px 0 0;
  }

  .el-form-item__content {
    height: 49px;
  }

  .el-input__inner {
    width: 360px;
    height: 49px;
    line-height: 49px;
    border: 1px solid #dddddd;
    border-radius: 0;
    color: #8a8a8a;
    padding: 0 15px;
  }

  .el-input__icon {
    width: 0.25rem;
    line-height: 49px;
  }

  .el-form-item__error--inline {
    margin-left: 9px;
    padding-top: 2px;
    font-size: 12px;
  }
}
</style>
